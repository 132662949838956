import LogUtils from "@norjs/utils/Log";
import WaStateName from "../../WaStateName";
import WaAttribute from "../../WaAttribute";
import NrModelUtils from "@norjs/ui/utils/NrModelUtils";
import NrEventName from "@norjs/ui/models/NrEventName";

const nrLog = LogUtils.getLogger(`WaCompileViewController`);

export class WaCompileViewController {

	static getBindings () {
		return {
			model: `<?${WaAttribute.MODEL}`
		};
	}

	/**
	 *
	 * @param $state {angular.ui.IState}
	 * @param $scope {angular.IScope}
	 * @param waModelService {WaModelService}
	 * @ngInject
	 */
	constructor (
		$state
		, $scope
		, waModelService
	) {
		'ngInject';

		/**
		 *
		 * @member {angular.ui.IState}
		 * @private
		 */
		this._$state = $state;

		/**
		 *
		 * @member {angular.IScope}
		 * @private
		 */
		this._$scope = $scope;

		/**
		 *
		 * @member {WaModelService}
		 * @private
		 */
		this._waModelService = waModelService;

		/**
		 *
		 * @member {NrModel}
		 * @private
		 */
		this._model = undefined;

	}

	// noinspection JSUnusedGlobalSymbols
	$onInit () {

		this._$scope.$on(NrEventName.BUTTON_CLICK,
			/**
			 *
			 * @param event
			 * @param buttonController {NrButtonController}
			 */
			(event, buttonController) => {

				if (event) {
					event.preventDefault();
					event.stopPropagation();
				}

				nrLog.trace(`Event NrEventName.BUTTON_CLICK ("${NrEventName.BUTTON_CLICK}") for button #${buttonController.id}`);

				this._buttonAction(buttonController);

			}
		);

		this._$scope.$on(NrEventName.FORM_SUBMIT,
			/**
			 *
			 * @param event
			 * @param payload {Object} The payload
			 * @param formController {NrFormController}
			 */
			(event, payload, formController) => {

				if (event) {
					event.preventDefault();
					event.stopPropagation();
				}

				if (payload) {

					nrLog.trace(`Event NrEventName.FORM_SUBMIT ("${NrEventName.FORM_SUBMIT}"): payload = `, payload);

					this._formSubmitAction(payload, formController);

				} else {

					nrLog.warn(`Warning! Event NrEventName.FORM_SUBMIT ("${NrEventName.FORM_SUBMIT}") without a payload value!`)

				}

			}
		);

		this._$scope.$on(NrEventName.FORM_CANCEL,
			/**
			 *
			 * @param event
			 * @param payload {Object} The payload
			 * @param formController {NrFormController}
			 */
			(event, payload, formController) => {

				if (event) {
					event.preventDefault();
					event.stopPropagation();
				}

				if (payload) {

					nrLog.trace(`Event NrEventName.FORM_CANCEL ("${NrEventName.FORM_CANCEL}"): payload = `, payload);

					this._formCancelAction(payload, formController);

				} else {

					nrLog.warn(`Warning! Event NrEventName.FORM_CANCEL ("${NrEventName.FORM_CANCEL}") without a payload value!`)

				}

			}
		);

	}

	$onDestroy () {

		this._$state = undefined;
		this._$scope = undefined;
		this._waModelService = undefined;
		this._model = undefined;

	}

	/**
	 *
	 * @returns {NrModel|undefined}
	 */
	get model () {

		return this._model;

	}

	/**
	 *
	 * @param value {NrModel|undefined}
	 */
	set model (value) {

		if (value !== this._model) {
			this._setModel(value);
		}

	}

	/**
	 *
	 * @returns {boolean}
	 */
	hasModel () {
		return this._model ? NrModelUtils.isModel(this._model) : false;
	}

	/**
	 *
	 * @returns {boolean}
	 */
	hasIcon () {
		return !!(this._model && this._model.icon)
	}

	/**
	 *
	 * @returns {NrIconValue|string|undefined}
	 */
	getIconValue () {
		return this._model && this._model.icon ? this._model.icon.value : undefined;
	}

	/**
	 *
	 * @param payload {NrModel}
	 * @private
	 */
	_setModel (payload) {

		this._model = payload;

		nrLog.debug(`Model set as: `, this._model);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 */
	goMain () {

		nrLog.info(`Moving state to INDEX (${WaStateName.INDEX})`);
		this._$state.go(WaStateName.INDEX);

	}

	/**
	 *
	 * @param buttonController {NrButtonController}
	 * @returns {angular.IPromise}
	 * @private
	 */
	_buttonAction (buttonController) {

		const model = buttonController.nrModel;

		nrLog.trace(`._buttonAction(): model: ${LogUtils.getAsString(model)}`);

		const action = model.click;

		if (!action) {
			throw new TypeError(`${nrLog.name}._buttonAction(): model did not have a click action`);
		}

		return this._waModelService.executeModel(action);

	}

	/**
	 *
	 * @param payload {Object}
	 * @param formController {NrFormController}
	 * @returns {angular.IPromise}
	 * @private
	 */
	_formSubmitAction (payload, formController) {

		const model = formController.nrModel;

		nrLog.trace(`._formSubmitAction(): model: ${LogUtils.getAsString(model)} with payload ${LogUtils.getAsString(payload)}`);

		const action = model.submit;

		if (!action) {
			throw new TypeError(`${nrLog.name}._formSubmitAction(): model did not have a submit action`);
		}

		const modifiedPayload = model.submit.payload ? _.merge({}, model.submit.payload, payload) : payload;

		return this._waModelService.executeModel(action, modifiedPayload);

	}

	/**
	 *
	 * @param payload {Object}
	 * @param formController {NrFormController}
	 * @returns {angular.IPromise}
	 * @private
	 */
	_formCancelAction (payload, formController) {

		const model = formController.nrModel;

		nrLog.trace(`._formCancelAction(): model: ${LogUtils.getAsString(model)}`);

		const action = model.cancel;

		if (!action) {
			throw new TypeError(`${nrLog.name}._formCancelAction(): model did not have a cancel action`);
		}

		const modifiedPayload = model.cancel.payload ? _.merge({}, model.cancel.payload, payload) : payload;

		return this._waModelService.executeModel(action, modifiedPayload);

	}

}

// noinspection JSUnusedGlobalSymbols
export default WaCompileViewController;
