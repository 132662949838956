import template from "./wa-main-view-template.html";
import { WaMainViewController } from "./WaMainViewController.js";
import "./wa-main-view-styles.scss";

export const waMainViewComponent = {
	template,
	controller: WaMainViewController,
	bindings: WaMainViewController.getBindings()
};

export default waMainViewComponent;
