import LogUtils from "@norjs/utils/Log";
import WaServiceName from "../WaServiceName";
import NrRequest from "@norjs/ui/models/NrRequest";
import WaModelStateUtils from "../../utils/WaModelStateUtils";
import {NrOption} from "@norjs/ui/models/views/fields/NrOption";

const nrLog = LogUtils.getLogger(WaServiceName.MODEL);

/**
 * Service which handles state changes and requests using NrModel's from Edge Backend.
 */
export class WaModelService {

    /**
     *
     * @returns {WaServiceName|string}
     */
    static get nrName () {
        return WaServiceName.MODEL;
    }

    /**
     *
     * @returns {typeof WaModelService}
     */
    get Class () {
        return WaModelService;
    }

    /**
     *
     * @returns {WaServiceName|string}
     */
    get nrName () {
        return this.Class.nrName;
    }

    /**
     *
     * @param nrRequestService {NrRequestService}
     * @param $state {angular.IState}
     * @ngInject
     */
    constructor (
        nrRequestService,
        $state
    ) {
        'ngInject';

        /**
         *
         * @type {NrRequestService}
         * @private
         */
        this._nrRequestService = nrRequestService;

        /**
         *
         * @type {angular.IState}
         * @private
         */
        this._$state = $state;

    }

    /**
     * Executes a `NrRequest` model using `NrRequestService` and uses `WaModelService.executeModel(response.payload)` to
     * handle returned response payload.
     *
     * @param model {NrRequest}
     * @param payload {Object}
     */
    executeRequest (model, payload = undefined) {

        if ( !(model && model instanceof NrRequest) ) {
            throw new TypeError(`${this.nrName}.executeRequest(): model not NrRequest: ${LogUtils.getAsString(model)}`);
        }

        if (payload) {
            model = NrRequest.copyWithModifiedPayload(model, payload);
        }

        return this._nrRequestService.executeRequest(model).then(response => {

            nrLog.trace(`.executeRequest(): response: ${LogUtils.getAsString(response)}`);

            if (!response.payload) {
                throw new TypeError(`${this.nrName}.executeRequest(): response did not have a payload`);
            }

            return this.executeModel(response.payload);

        }).catch( err => {

            nrLog.error(`.executeRequest(): Error: `, err);

            const state = WaModelStateUtils.getErrorState(err);

            nrLog.info(`.executeRequest(): Moving state to ${state.name} with `, state.params);

            return this._$state.go(state.name, state.params);

        });

    }

    /**
     * Executes any NrModel model as an action:
     *
     *  1. If it is instance of `NrRequest`, uses `WaModelService.executeRequest(model, ...)`
     *  2. Otherwise uses `WaModelStateUtils.getState(...)` and `$state.go(...)` to move to correct view to handle it
     *
     * @param model {NrModel}
     * @param payload {Object}
     * @return {angular.IPromise}
     * @fixme Implement ErrorView state, and move there instead of exception.
     */
    executeModel (model, payload = undefined) {

        if ( !model ) {
            throw new TypeError( `${this.nrName}.executeModel(): model not defined: ${LogUtils.getAsString(model)}`);
        }

        if ( model instanceof NrRequest ) {

            if (payload) {
                model = NrRequest.copyWithModifiedPayload(model, payload);
            }

            nrLog.trace(`.executeModel(): model (NrRequest): `, model);

            return this.executeRequest(model);

        }

        if ( model instanceof NrOption && model.value instanceof NrRequest ) {

            nrLog.trace(`.executeModel(): model (NrOption with NrRequest): `, model);

            if (payload) {
                return this.executeRequest(NrRequest.copyWithModifiedPayload(model.value, payload));
            }

            return this.executeRequest(model.value);

        }

        nrLog.trace(`.executeModel(): model: `, model);

        const state = WaModelStateUtils.getState(model);

        if (state) {

            nrLog.info(`.executeRequest(): Moving state to ${state.name} with `, state.params);
            return this._$state.go(state.name, state.params);

        }

        nrLog.trace(`.executeModel(): state: `, state);

        throw new TypeError(`${this.nrName}.executeModel(): Unsupported model: ${LogUtils.getAsString(model)}`);

    }

    /**
     *
     * @param err {*}
     */
    handleError (err) {

        nrLog.error(`.handleError(): err =`, err);

        const state = WaModelStateUtils.getErrorState(err);

        nrLog.info(`.handleError(): Moving state to ${state.name} with `, state.params);

        return this._$state.go(state.name, state.params);

    }

}

export default WaModelService;
