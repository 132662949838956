/**
 * Work Assistant Service Names
 *
 * @enum {string}
 * @readonly
 */
export const WaServiceName = {

      MODEL         : "waModelService"
    , EVENT         : "waEventService"

};

// noinspection JSUnusedGlobalSymbols
export default WaServiceName;
