import template from "./wa-confirm-dialog-view-template.html";
import { WaConfirmDialogViewController } from "./waConfirmDialogViewController.js";
import "./wa-confirm-dialog-view-styles.scss";

export const waConfirmDialogViewComponent = {
	template,
	controller: WaConfirmDialogViewController,
	bindings: WaConfirmDialogViewController.getBindings()
};

export default waConfirmDialogViewComponent;
