import template from "./wa-index-view-template.html";
import { WaIndexViewController } from "./WaIndexViewController.js";
import "./wa-index-view-styles.scss";

export const waIndexViewComponent = {
	template,
	controller: WaIndexViewController,
	bindings: WaIndexViewController.getBindings()
};

export default waIndexViewComponent;
