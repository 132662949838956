import template from "./wa-form-view-template.html";
import { WaFormViewController } from "./waFormViewController.js";
import "./wa-form-view-styles.scss";

export const waFormViewComponent = {
	template,
	controller: WaFormViewController,
	bindings: WaFormViewController.getBindings()
};

export default waFormViewComponent;
