/**
 *
 * @ngInject
 */
class NrMainViewController {

  get nrName () {
    return "nrMainViewController";
  }

  /**
   *
   * @ngInject
   */
  constructor () {
    'ngInject';
  }

}

export default NrMainViewController;
