import NrConfirmDialog from "@norjs/ui/models/views/NrConfirmDialog";
import LogUtils from "@norjs/utils/Log";
import WaStateName from "../../WaStateName";
import WaAttribute from "../../WaAttribute";

const nrLog = LogUtils.getLogger(`WaConfirmDialogViewController`);

export class WaConfirmDialogViewController {

	/**
	 *
	 * @returns {string}
	 */
	static get nrName () {
		return "waConfirmDialogViewController";
	}

	/**
	 *
	 * @returns {typeof WaConfirmDialogViewController}
	 */
	get Class () {
		return WaConfirmDialogViewController;
	}

	/**
	 *
	 * @returns {string}
	 */
	get nrName () {
		return this.Class.nrName;
	}

	/**
	 *
	 * @returns {{bindWaModel: string}}
	 */
	static getBindings () {
		return {
			bindWaModel: `<?${WaAttribute.MODEL}`
		};
	}

	/**
	 *
	 * @param $state {angular.IState}
	 * @param waModelService {WaModelService}
	 * @ngInject
	 */
	constructor (
		$state
		, waModelService
	) {
		'ngInject';

		/**
		 *
		 * @member {WaModelService}
		 * @private
		 */
		this._waModelService = waModelService;

		/**
		 *
		 * @member {angular.IState}
		 * @private
		 */
		this._$state = $state;

		/**
		 *
		 * @member {NrConfirmDialog}
		 * @private
		 */
		this._model = undefined;

	}

	$onDestroy () {

		this._waModelService = undefined;
		this._$state = undefined;
		this._model = undefined;

		nrLog.trace(`Destroyed`);

	}

	/**
	 *
	 * @returns {NrConfirmDialog|undefined}
	 */
	get model () {
		return this._model;
	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 * @returns {NrConfirmDialog|undefined}
	 */
	get bindWaModel () {

		return this._model;

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 * @param value {NrConfirmDialog|undefined}
	 */
	set bindWaModel (value) {

		if (value !== this._model) {
			this._setModel(value);
		}

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 */
	acceptAction () {

		nrLog.trace(`acceptAction(): model: ${LogUtils.getAsString(this._model)}`);

		const action = this._model.accept;

		if (!action) {
			throw new TypeError(`${this.nrName}.acceptAction(): model did not have an accept action`);
		}

		return this._waModelService.executeModel(action);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 */
	cancelAction () {

		nrLog.trace(`cancelAction(): model: ${LogUtils.getAsString(this._model)}`);

		const action = this._model.cancel;

		if (!action) {
			throw new TypeError(`${this.nrName}.cancelAction(): model did not have a cancel action`);
		}

		return this._waModelService.executeModel(action);

	}

	/**
	 *
	 * @returns {boolean}
	 */
	isConfirmDialog () {
		return this._model ? this._model instanceof NrConfirmDialog : false;
	}

	/**
	 *
	 * @param payload {NrConfirmDialog}
	 * @private
	 */
	_setModel (payload) {

		this._model = payload;

		nrLog.debug(`Model set as: `, this._model);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 * Used from the template.
	 */
	goMain () {

		nrLog.info(`Moving state to INDEX (${WaStateName.INDEX})`);
		this._$state.go(WaStateName.INDEX);

	}

}

// noinspection JSUnusedGlobalSymbols
export default WaConfirmDialogViewController;
