import template from "./wa-compile-view-template.html";
import { WaCompileViewController } from "./WaCompileViewController.js";
import "./wa-compile-view-styles.scss";

export const waCompileViewComponent = {
	template,
	controller: WaCompileViewController,
	bindings: WaCompileViewController.getBindings()
};

export default waCompileViewComponent;
