
/**
 *
 * @type {*[]}
 */
export const THEMES = [
    "iron",
    "midnight-blue"
];

/**
 *
 * @type {string}
 */
export const PREFERRED_LANGUAGE = 'fi';

/**
 *
 * @type {string}
 */
export const API_PATH_PREFIX = '/api';

/**
 * The API root path, without `API_PATH_PREFIX`.
 *
 * @type {string}
 */
export const API_INDEX_PATH = '/';

/**
 * The API nav path, without `API_PATH_PREFIX`.
 *
 * @type {string}
 */
export const API_NAV_PATH = '/nav/';
