
/**
 * @enum {string}
 * @readonly
 */
export const WaObjectType = {

    CHAT_MESSAGE : 'WaChatMessage',
    WORK_RECORD  : 'BeWorkRecord',
    WORK_PROJECT : 'BeWorkProject'

};

// noinspection JSUnusedGlobalSymbols
export default WaObjectType;
