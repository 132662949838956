/**
 * Backend API paths
 *
 * @enum {string}
 * @readonly
 */
export const BePath = {

    /**
     * @member {BePath|string}
     */
    INDEX : "/",

    /**
     * @member {BePath|string}
     */
    LAYOUT : "/layout/",

    /**
     * @member {BePath|string}
     */
    NAV : "/nav/",

    /**
     * @member {BePath|string}
     */
    CHAT : "/chat/",

    /**
     * @member {BePath|string}
     */
    WORK : "/work/",

    /**
     * @member {BePath|string}
     */
    TASK : "/task/",

    /**
     * @member {BePath|string}
     */
    EVENT : "/event/",

    /**
     * @member {BePath|string}
     */
    AUTHENTICATE : "/authenticate/",

    /**
     * @member {BePath|string}
     */
    LOGOUT : "/logout/"

};

// noinspection JSUnusedGlobalSymbols
export default BePath;
