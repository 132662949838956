import angular from 'angular';
import WaModuleName from "../WaModuleName";
import WaServiceName from "../WaServiceName";
import WaModelService from './WaModelService.js';
import WaEventService from './WaEventService.js';

export const waServices = angular.module(
  WaModuleName.SERVICES
  , [
  ])
  .service(WaServiceName.MODEL, WaModelService)
  .service(WaServiceName.EVENT, WaEventService)
  .name;

export default waServices;
