/**
 * Work Assistant View Names
 *
 * @enum {string}
 * @readonly
 */
export const WaStateName = {

    MAIN             : "main"
    , INDEX          : "main.index"
    , FORM           : "main.form"
    , CONFIRM_DIALOG : "main.confirmDialog"
    , MESSAGE        : "main.message"
    , COMPILE        : "main.compile"

};

// noinspection JSUnusedGlobalSymbols
export default WaStateName;
