import NrForm from "@norjs/ui/models/views/NrForm";
import LogUtils from "@norjs/utils/Log";
import WaStateName from "../../WaStateName";
import WaAttribute from "../../WaAttribute";

const nrLog = LogUtils.getLogger(`WaFormViewController`);

export class WaFormViewController {

	static get nrName () {
		return "waFormViewController";
	}

	get Class () {
		return WaFormViewController;
	}

	get nrName () {
		return this.Class.nrName;
	}

	static getBindings () {
		return {
			model: `<?${WaAttribute.MODEL}`
		};
	}

	/**
	 *
	 * @param $state {angular.IState}
	 * @param waModelService {WaModelService}
	 * @ngInject
	 */
	constructor (
		$state
		, waModelService
	) {
		'ngInject';

		/**
		 *
		 * @member {angular.IState}
		 * @private
		 */
		this._$state = $state;

		/**
		 *
		 * @member {WaModelService}
		 * @private
		 */
		this._waModelService = waModelService;

		/**
		 *
		 * @member {NrForm}
		 * @private
		 */
		this._model = undefined;

	}

	$onDestroy () {

		this._waModelService = undefined;
		this._$state = undefined;
		this._model = undefined;

	}

	/**
	 *
	 * @returns {NrForm|undefined}
	 */
	get model () {

		return this._model;

	}

	/**
	 *
	 * @param value {NrForm|undefined}
	 */
	set model (value) {

		if (value !== this._model) {
			this._setModel(value);
		}

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 */
	submitAction (payload) {

		nrLog.trace(`submitAction(): model: ${LogUtils.getAsString(this._model)} with payload ${LogUtils.getAsString(payload)}`);

		const action = this._model.submit;

		if (!action) {
			throw new TypeError(`${nrLog.name}.submitAction(): model did not have a submit action`);
		}

		const modifiedPayload = this._model.submit.payload ? _.merge({}, this._model.submit.payload, payload) : payload;

		return this._waModelService.executeModel(action, modifiedPayload);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 */
	cancelAction (payload) {

		nrLog.trace(`cancelAction(): model: ${LogUtils.getAsString(this._model)}`);

		const action = this._model.cancel;

		if (!action) {
			throw new TypeError(`${nrLog.name}.cancelAction(): model did not have a cancel action`);
		}

		const modifiedPayload = this._model.cancel.payload ? _.merge({}, this._model.cancel.payload, payload) : payload;

		return this._waModelService.executeModel(action, modifiedPayload);

	}

	/**
	 *
	 * @returns {boolean}
	 */
	isForm () {
		return this._model ? this._model instanceof NrForm : false;
	}

	/**
	 *
	 * @param payload {NrForm}
	 * @private
	 */
	_setModel (payload) {

		this._model = payload;

		nrLog.debug(`Model set as: `, this._model);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 * Used from the template.
	 */
	goMain () {

		nrLog.info(`Moving state to INDEX (${WaStateName.INDEX})`);

		this._$state.go(WaStateName.INDEX);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 * @returns {boolean}
	 */
	isCancelVisible () {

		return !! ( this._model && ( this._model.cancel || this._model.cancelButton ) );

	}

}

// noinspection JSUnusedGlobalSymbols
export default WaFormViewController;
