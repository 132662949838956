import LogUtils from "@norjs/utils/Log";
import WaServiceName from "../WaServiceName";
import BePath from "../../../backend/src/beViews/BePath";
import {NrRequest} from "@norjs/ui/models/NrRequest";
import {NrEventList} from "@norjs/ui/models/NrEventList";

// noinspection JSUnusedLocalSymbols
const nrLog = LogUtils.getLogger(WaServiceName.EVENT);

// Disabled for development purposes
nrLog.setLogLevel(nrLog.LogLevel.WARN);

/**
 * The time limit (ms) which to consider flooding if backend gives empty result too fast.
 *
 * @type {number}
 */
const WA_EVENT_FETCH_FLOOD_LIMIT = 1000;

/**
 * The timeout (ms) which to wait until next try if we're getting empty requests too fast.
 *
 * @type {number}
 */
const WA_EVENT_FETCH_FLOOD_TIMEOUT = 2500;

/**
 * The timeout (ms) which to wait until next try if errors happen.
 *
 * @type {number}
 */
const WA_EVENT_FETCH_ERROR_TIMEOUT = 5000;

/**
 *
 * @enum {string}
 * @readonly
 */
export const WaEventServiceState = {

    /**
     * The service is uninitialized.
     */
    UNDEFINED: 'UNDEFINED',

    /**
     * We're fetching events normally, eg. waiting response from the backend.
     */
    READY: 'READY',

    /**
     * This is the state when we could not connect to the backend.
     */
    OFFLINE: 'OFFLINE'

};

/**
 * Service which polls NrEvent's from the edge backend
 *
 * @FIXME: This service should have its own event emitter implementation and not relay on $rootScope
 */
export class WaEventService {

    /**
     *
     * @returns {WaServiceName|string}
     */
    static get nrName () {
        return WaServiceName.EVENT;
    }

    /**
     *
     * @returns {typeof WaEventService}
     */
    get Class () {
        return WaEventService;
    }

    /**
     *
     * @returns {WaServiceName|string}
     */
    get nrName () {
        return this.Class.nrName;
    }

    /**
     *
     * @param nrRequestService {NrRequestService}
     * @param $timeout {angular.ITimeoutService}
     * @param $rootScope {angular.IRootScopeService}
     * @ngInject
     */
    constructor (
        nrRequestService
        , $timeout
        , $rootScope
    ) {
        'ngInject';

        /**
         *
         * @member {WaEventServiceState|string}
         * @private
         */
        this._state = WaEventServiceState.UNDEFINED;

        /**
         *
         * @member {NrRequestService}
         * @private
         */
        this._nrRequestService = nrRequestService;

        /**
         *
         * @member {angular.ITimeoutService}
         * @private
         */
        this._$timeout = $timeout;

        /**
         *
         * @member {angular.IRootScopeService}
         * @private
         */
        this._$rootScope = $rootScope;

        /**
         *
         * @member {angular.IPromise|undefined}
         * @private
         */
        this._fetchTimeout = undefined;

    }

    initialize () {

        this._fetchEvents()

    }

    /**
     * Returns `true` if we're online, eg. receiving events normally.
     *
     * @returns {boolean}
     */
    isOnline () {
        return this._state === WaEventServiceState.READY;
    }

    /**
     * Returns `true` if we're offline, eg. there was (any) errors.
     *
     * @returns {boolean}
     */
    isOffline () {
        return this._state === WaEventServiceState.OFFLINE;
    }

    /**
     *
     * @returns {angular.IPromise}
     * @private
     */
    _fetchEvents () {

        if (this._fetchTimeout) {
            this._fetchTimeout.cancel();
            this._fetchTimeout = undefined;
            nrLog.warn(`Cancelled previous fetch for events.`);
        }

        const request = new NrRequest({
            href: BePath.EVENT
        });

        nrLog.trace(`._fetchEvents(): request: `, request);

        const startTime = Date.now();

        return this._nrRequestService.executeRequest(request).then(
            /**
             *
             * @param response {NrResponse}
             */
            response => {

                nrLog.trace(`._fetchEvents(): response: `, response);

                /**
                 *
                 * @type {NrEventList|undefined}
                 */
                const eventList = response ? response.payload : undefined;

                nrLog.trace(`._fetchEvents(): eventList: `, eventList);

                if ( !( eventList && (eventList instanceof NrEventList)) ) {
                    throw new TypeError(`${this.nrName}._fetchEvents(): No event list in the reply.`);
                }

                const durationTime = Date.now() - startTime;
                nrLog.trace(`._fetchEvents(): durationTime: `, durationTime);

                this._state = WaEventServiceState.READY;

                const responseEventAmount = eventList.content.length;

                if ( durationTime < WA_EVENT_FETCH_FLOOD_LIMIT && responseEventAmount === 0 ) {

                    nrLog.warn(`Backend responded too fast (${durationTime} ms of ${WA_EVENT_FETCH_FLOOD_LIMIT} limit) with an empty event package. We'll wait for ${WA_EVENT_FETCH_FLOOD_TIMEOUT} ms.`);

                    const timeout = this._fetchTimeout = this._$timeout(() => {

                        if ( this._fetchTimeout && timeout === this._fetchTimeout ) {
                            this._fetchTimeout = undefined;
                        }

                        this._fetchEvents();

                    }, WA_EVENT_FETCH_FLOOD_TIMEOUT);

                } else {

                    this._processEventList(eventList);

                    this._fetchEvents();

                }

            }
        ).catch(err => {

            nrLog.error(`._fetchEvents(): Error: `, err);

            this._state = WaEventServiceState.OFFLINE;

            nrLog.warn(`Backend responded with an error. We'll wait for ${WA_EVENT_FETCH_ERROR_TIMEOUT} ms.`);
            const timeout = this._fetchTimeout = this._$timeout(() => {

                if ( this._fetchTimeout && timeout === this._fetchTimeout ) {
                    this._fetchTimeout = undefined;
                }

                this._fetchEvents();

            }, WA_EVENT_FETCH_ERROR_TIMEOUT);

        });

    }

    /**
     *
     * @param eventList {NrEventList}
     * @private
     */
    _processEventList (eventList) {

        try {

            _.each(
                eventList.content,
                event => {
                    nrLog.trace(`._processEventList(): event = `, event);
                    this._processEvent(event);
                }
            );

        } catch (err) {

            nrLog.error(`._processEvents(): Error: `, err);

        }

    }

    /**
     *
     * @param event {NrEvent}
     * @private
     */
    _processEvent (event) {

        try {

            nrLog.trace(`._processEvent(): event = `, event);

            const name = event.name;
            const payload = event.payload;

            nrLog.trace(`._processEvent(): name = "${name}"`);
            nrLog.trace(`._processEvent(): payload = "${LogUtils.getAsString(payload)}"`);

            this._$rootScope.$broadcast(name, payload, event);

        } catch (err2) {

            nrLog.error(`._processEvents(): Error: `, err2);

        }

    }

}

// noinspection JSUnusedGlobalSymbols
export default WaEventService;
