import angular from 'angular';
import WaModuleName from "../WaModuleName";

export const waCommonModule = angular.module(
	WaModuleName.COMMON
	, [
		// Keep in the same format, it helps with git merges
	])
	.name;

export default waCommonModule;
