import LogUtils from "@norjs/utils/Log";
import NrRequest from "@norjs/ui/models/NrRequest";
import {API_NAV_PATH} from "../../waConstants";
import NrEventName from "@norjs/ui/models/NrEventName";
import {BeEventName} from "../../../../BeEventName";

// noinspection JSUnusedLocalSymbols
const nrLog = LogUtils.getLogger("WaMainViewController");

/**
 * This service handles state changes based on NrModel objects.
 */
export class WaMainViewController {

	/**
	 *
	 * @returns {string}
	 */
	static get nrName () {
		return "WaMainViewController";
	}

	/**
	 *
	 * @returns {typeof WaMainViewController}
	 */
	get Class () {
		return WaMainViewController;
	}

	/**
	 *
	 * @returns {string}
	 */
	get nrName () {
		return this.Class.nrName;
	}

	/**
	 *
	 * @returns {{}}
	 */
	static getBindings () {
		return {};
	}

	/**
	 *
	 * @param $scope {angular.IScope}
	 * @param nrRequestService {NrRequestService}
	 * @param waModelService {WaModelService}
	 * @ngInject
	 */
	constructor (
		$scope,
		nrRequestService,
		waModelService
	) {
		'ngInject';

		/**
		 *
		 * @type {angular.IScope}
		 * @private
		 */
		this._$scope = $scope;

		/**
		 *
		 * @member {NrRequestService}
		 * @private
		 */
		this._nrRequestService = nrRequestService;

		/**
		 *
		 * @member {WaModelService}
		 * @private
		 */
		this._waModelService = waModelService;

		/**
		 *
		 * @member {NrModel|undefined}
		 */
		this._nav = undefined;

	}

	/**
	 *
	 * @returns {NrModel|undefined}
	 */
	get nav () {
		return this._nav;
	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 * AngularJS calls this.
	 */
	$onInit () {

		this._initializeNavFromBackend();

		this._$scope.$on(NrEventName.NAV_CLICK, (event, item) => {

			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}

			if (item) {

				nrLog.trace(`Event NrEventName.NAV_CLICK ("${NrEventName.NAV_CLICK}"): item = `, item);
				this._onNavItemClick(item);

			} else {

				nrLog.warn(`Warning! Event NrEventName.NAV_CLICK ("${NrEventName.NAV_CLICK}") without a model value!`)

			}

		});

		this._$scope.$on(BeEventName.NAV_CHANGED, () => {

			nrLog.trace(`NAV_CHANGED event from the backend; initializing nav again.`);

			this._initializeNavFromBackend();

		});

		this._$scope.$on(BeEventName.SESSION_CHANGED, () => {

			nrLog.trace(`SESSION_CHANGED event from the backend; initializing nav again.`);

			this._initializeNavFromBackend();

		});

	}

	/**
	 *
	 * @private
	 */
	_initializeNavFromBackend () {

		// FIXME: There should be a cancel and/or a throttle if this is called from multiple events

		const request = new NrRequest({
			href: API_NAV_PATH
		});

		nrLog.trace(`._initializeNavFromBackend(): request = `, request);

		this._nav = undefined;

		this._nrRequestService.executeRequest(request).then(response => {

			nrLog.trace(`._initializeNavFromBackend(): response = `, response);

			const { payload } = response;

			if (!payload) {
				throw new TypeError(`${this.nrName}._requestInitialData: response's payload not defined: ${LogUtils.getAsString(payload)}`);
			}

			this._nav = payload;

			nrLog.info(`._initializeNavFromBackend(): Nav set as: `, payload);

		}).catch( err => {

			nrLog.error(`._initializeNavFromBackend(): Error: `, err);

			return this._waModelService.handleError(err);

		});

	}

	/**
	 *
	 * @param item {NrModel}
	 * @private
	 */
	_onNavItemClick (item) {

		if (!item) {
			throw new TypeError(`${this.nrName}._onNavItemClick(): item not defined: ${LogUtils.getAsString(item)}`);
		}

		nrLog.trace(`._onNavItemClick(): item = `, item);

		return this._waModelService.executeModel(item);

	}

}

// noinspection JSUnusedGlobalSymbols
export default WaMainViewController;
