import NrRequestService from "@norjs/ui/src/services/NrRequestService";
import {API_PATH_PREFIX, PREFERRED_LANGUAGE} from "./waConstants";
import TRANSLATIONS from "./waTranslations";
import "./wa-styles.scss";
import "./wa-themes.scss";
import uiRouter from "angular-ui-router";
import nrUiModule from "@norjs/ui";
import waCommonModule from "./waCommon/waCommonModule";
import waViewsModule from "./waViews/waViewsModule";
import waServicesModule from "./waServices/waServicesModule";
import NrModelUtils from "@norjs/ui/utils/NrModelUtils";
import {BeChatMessage} from "../../backend/src/beViews/beChatView/BeChatMessage";

/**
 *
 */
export class WaMain {

    /**
     *
     * @returns {string[]}
     */
    static getDependencies () {
        return [
            uiRouter
            , nrUiModule
            , waCommonModule
            , waViewsModule
            , waServicesModule
        ];
    }

    /**
     *
     * @param $translateProvider
     * @ngInject
     */
    static config (
        $translateProvider
    ) {
        'ngInject';

        // Setup nrRequestService
        NrRequestService.API_PATH_PREFIX = API_PATH_PREFIX;

        // Setup translation
        _.forEach(_.keys(TRANSLATIONS), key => {
            $translateProvider.translations(key, TRANSLATIONS[key]);
        });

        $translateProvider.preferredLanguage(PREFERRED_LANGUAGE);

        // Register custom models
        NrModelUtils.registerModel(BeChatMessage);

    }

    /**
     * @param waEventService {WaEventService}
     * @ngInject
     */
    static run (
        waEventService
    ) {
        'ngInject';

        // Start event long polling
        waEventService.initialize();

    }

}

export default WaMain;
