import template from "./wa-message-view-template.html";
import { WaMessageViewController } from "./waMessageViewController.js";
import "./wa-message-view-styles.scss";

export const waMessageViewComponent = {
	template,
	controller: WaMessageViewController,
	bindings: WaMessageViewController.getBindings()
};

export default waMessageViewComponent;
