import angular from 'angular';
import WaModuleName from "../WaModuleName";
import waMainViewModule from './waMainView/waMainViewModule.js';
import waIndexViewModule from "./waIndexView/waIndexViewModule";
import waConfirmDialogViewModule from "./waConfirmDialogView/waConfirmDialogViewModule";
import waMessageViewModule from "./waMessageView/waMessageViewModule";
import waFormViewModule from "./waFormView/waFormViewModule";
import waCompileViewModule from "./waCompileView/waCompileViewModule";

export const waViewsModule = angular.module(
	WaModuleName.VIEWS
	, [
		waMainViewModule
		, waIndexViewModule
		, waConfirmDialogViewModule
		, waMessageViewModule
		, waFormViewModule
		, waCompileViewModule
		// Keep in the same format, it helps with git merges
	])
	.name;

export default waViewsModule;
