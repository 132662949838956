/**
 * Work Assistant Tag Names
 *
 * @enum {string}
 * @readonly
 */
export const WaTag = {

    INDEX_VIEW             : "waIndexView"
    , NAV_VIEW             : "waNavView"
    , MAIN_VIEW            : "waMainView"
    , CONFIRM_DIALOG_VIEW  : "waConfirmDialogView"
    , MESSAGE_VIEW         : "waMessageView"
    , FORM_VIEW            : "waFormView"
    , COMPILE_VIEW         : "waCompileView"

};

// noinspection JSUnusedGlobalSymbols
export default WaTag;
