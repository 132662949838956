/**
 * Work Assistant Attribute Names
 *
 * @enum {string}
 * @readonly
 */
export const WaAttribute = {

    ID      : "waId"
    , MODEL : "waModel"

};

// noinspection JSUnusedGlobalSymbols
export default WaAttribute;
