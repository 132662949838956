/**
 * @enum {string}
 * @readonly
 */
export const BeEventName = {

    /**
     * Session has been changed. (Eg. authenticated/unauthenticated.)
     */
    SESSION_CHANGED: 'NrSession:changed',

    /**
     * The nav has been changed and should be reloaded
     */
    NAV_CHANGED: 'BeNavComponent:nav:changed',

    /**
     * Backend asks to move to this model view
     */
    OPEN_MODEL_VIEW: 'NrModel:open'

};

// noinspection JSUnusedGlobalSymbols
export default BeEventName;
