/**
 *
 * @enum {string}
 * @readonly
 */
export const WaModuleName = {
    APP                   : "wa"
    , COMMON              : "wa.waCommon"
    , SERVICES            : "wa.waServices"
    , VIEWS               : "wa.waViews"
    , INDEX_VIEW          : "wa.waViews.waIndexView"
    , NAV_VIEW            : "wa.waViews.waNavView"
    , FORM_VIEW           : "wa.waViews.waFormView"
    , MAIN_VIEW           : "wa.waViews.waMainView"
    , CONFIRM_DIALOG_VIEW : "wa.waViews.waConfirmDialogView"
    , MESSAGE_VIEW        : "wa.waViews.waMessageView"
    , COMPILE_VIEW        : "wa.waViews.waCompileView"

};

// noinspection JSUnusedGlobalSymbols
export default WaModuleName;
