import angular from 'angular';
import waIndexViewComponent from './waIndexViewComponent.js';
import WaModuleName from "../../WaModuleName";
import WaTag from "../../WaTag";

export const waIndexViewModule = angular.module(
	WaModuleName.INDEX_VIEW
	, [
	])
    .component(WaTag.INDEX_VIEW, waIndexViewComponent)
	.name;

export default waIndexViewModule;
