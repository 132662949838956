import LogUtils from "@norjs/utils/Log";
import NrModelUtils from "@norjs/ui/utils/NrModelUtils";
import WaAttribute from "../src/WaAttribute";
import WaStateName from "../src/WaStateName";
import NrErrorMessage from "@norjs/ui/models/views/NrErrorMessage";
import _ from "lodash";
import { NrIcon } from "@norjs/ui/models/NrIcon";
import NrIconValue from "@norjs/ui/models/NrIconValue";

/**
 *
 * @type {string}
 */
const NODE_ENV = process.env.NODE_ENV ? _.toLower(process.env.NODE_ENV) : 'development';

/**
 *
 * @type {boolean}
 */
const IS_DEVELOPMENT = NODE_ENV === 'development';

const nrLog = LogUtils.getLogger("WaModelStateUtils");

/**
 * Service which you can use to check which view handles your model.
 *
 * @see https://www.w3.org/TR/webstorage/
 */
export class WaModelStateUtils {

    static get nrName () {
        return "WaModelStateUtils";
    }

    /**
     *
     * @ngInject
     * @private
     */
    constructor () {}

    /**
     * Get state name and params for specific view for this model object.
     *
     * @param model {NrModel}
     * @return {{name: WaStateName|string, params: Object}|undefined}
     */
    static getState (model) {

        if (!model) {
            throw new TypeError(`${this.nrName}.getState(): model not defined: ${LogUtils.getAsString(model)}`);
        }

        if (NrModelUtils.isConfirmDialog(model)) {
            return {
                name: WaStateName.CONFIRM_DIALOG,
                params: {[WaAttribute.MODEL]: model}
            };
        }

        if (NrModelUtils.isForm(model)) {
            return {
                name: WaStateName.FORM,
                params: {[WaAttribute.MODEL]: model}
            };
        }

        if (NrModelUtils.isMessage(model)) {
            return {
                name: WaStateName.MESSAGE,
                params: {[WaAttribute.MODEL]: model}
            };
        }

        if (NrModelUtils.isOption(model)) {
            nrLog.trace(`.getState(): model was NrOption, using it's value instead: `, model.value);
            return this.getState(model.value);
        }

        if (NrModelUtils.isModel(model)) {
            return {
                name: WaStateName.COMPILE,
                params: {[WaAttribute.MODEL]: model}
            };
        }

        return undefined;

    }

    /**
     *
     * @param err {*}
     * @return {{name: WaStateName|string, params: Object}|undefined}
     */
    static getErrorState (err) {

        nrLog.trace(`.getErrorState(): Error: `, err);

        let state = this.getState(err);

        if (!state) {

            const summary = IS_DEVELOPMENT ? _.get(err, 'payload.summary', undefined) : undefined;

            state = this.getState(new NrErrorMessage({
                icon    : new NrIcon({
                    value: NrIconValue.EXCLAMATION_CIRCLE
                }),
                label   : `${ this._getErrorKey(err)}`,
                content : IS_DEVELOPMENT ? LogUtils.getAsString(err, {multiLine: true}) + ( summary ? '\n\n' + summary : '') : undefined
            }));

        }

        return state;

    }

    /**
     *
     * @param err {*}
     * @returns {string}
     * @private
     */
    static _getErrorKey (err) {

        if ( _.isString(err.error) && /^[a-zA-Z0-9\.]+/.test(err.error) ) {
            return err.error;
        }

        if ( _.isNumber(err.code) ) {

            nrLog.trace(`._getErrorKey(): Using .code from err = `, err);

            return `errors.${err.code}`;

        }

        nrLog.trace(`._getErrorKey(): Using err = `, err);

        return `errors.${this._stringifyValue(err)}`;

    }

    /**
     * Parses any variable as a simple string keyword.
     *
     * @param value
     * @returns {string}
     * @private
     */
    static _stringifyValue (value) {

        let words = _.trim(LogUtils.getAsString(value).replace(/[^a-zA-Z0-9]+/g, " ").replace(/ +/g, " ")).split(' ');

        if (words.length > 10) {
            words = words.splice(0, 10);
        }

        return words.join('-');

    }

}

export default WaModelStateUtils;
