import NrRequest from "@norjs/ui/models/NrRequest";
import LogUtils from "@norjs/utils/Log";
import {API_INDEX_PATH} from "../../waConstants";

const nrLog = LogUtils.getLogger(`WaIndexViewController`);

export class WaIndexViewController {

	/**
	 *
	 * @returns {string}
	 */
	static get nrName () {
		return "WaIndexViewController";
	}

	/**
	 *
	 * @returns {typeof WaIndexViewController}
	 */
	get Class () {
		return WaIndexViewController;
	}

	/**
	 *
	 * @returns {string}
	 */
	get nrName () {
		return this.Class.nrName;
	}

	/**
	 *
	 * @returns {{}}
	 */
	static getBindings () {
		return {};
	}

	/**
	 *
	 * @param waModelService {WaModelService}
	 * @param $state {angular.ui.IState}
	 * @ngInject
	 */
	constructor (
		waModelService
		, $state
	) {
		'ngInject';

		/**
		 *
		 * @member {WaModelService}
		 * @private
		 */
		this._waModelService = waModelService;

	}

	// noinspection JSUnusedGlobalSymbols
	$onInit () {

		this._requestInitialData();

	}

	/**
	 *
	 * @private
	 */
	_requestInitialData () {

		const request = new NrRequest({
			href: API_INDEX_PATH
		});

		nrLog.trace(`._requestInitialData(): request = `, request);

		this._waModelService.executeRequest(request);

	}

}

// noinspection JSUnusedGlobalSymbols
export default WaIndexViewController;
