import _ from "lodash";
import WaObjectType from "../../../../WaObjectType";
import NrModelUtils from "@norjs/ui/utils/NrModelUtils";

/**
 * This is a data model for a chat message.
 *
 * @implements {NrModel}
 */
export class BeChatMessage {

    /**
     *
     * @returns {string}
     */
    static get nrName () {
        return WaObjectType.CHAT_MESSAGE;
    }

    /**
     *
     * @returns {typeof BeChatMessage}
     */
    get Class () {
        return BeChatMessage;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     *
     * @returns {string}
     */
    get nrName () {
        return this.Class.nrName;
    }

    /**
     *
     * @param [userId] {string}
     * @param [content] {string}
     * @param [payload] {*|undefined}
     */
    constructor ({
        userId,
        content,
        payload = undefined
    } = {}) {

        if ( !( userId && _.isString(userId) ) ) {
            throw new TypeError(`new ${BeChatMessage.nrName}(): userId invalid: "${userId}"`);
        }

        if ( !( content && _.isString(content) ) ) {
            throw new TypeError(`new ${BeChatMessage.nrName}(): content invalid: "${content}"`);
        }

        /**
         *
         * @member {string}
         * @protected
         */
        this._userId = userId;

        /**
         *
         * @member {string}
         * @protected
         */
        this._content = content;

        /**
         *
         * @member {*}
         * @private
         */
        this._payload = payload ? Object.freeze(payload) : undefined;

    }

    /**
     *
     * @returns {string}
     */
    get type () {
        return WaObjectType.CHAT_MESSAGE;
    }

    /**
     *
     * @returns {string}
     */
    get userId () {
        return this._userId;
    }

    /**
     *
     * @returns {string}
     */
    get content () {
        return this._content;
    }

    /**
     *
     * @returns {*}
     */
    get payload () {
        return this._payload;
    }

    /**
     *
     * @returns {Object}
     */
    valueOf () {
        return {
            type: this.type,
            userId: this._userId,
            content: this._content,
            payload: this._payload ? (NrModelUtils.isModel(this._payload) ? NrModelUtils.valueOf(this._payload) : _.cloneDeep(this._payload)) : null
        };
    }

    /**
     *
     * @returns {Object}
     */
    toJSON () {
        return this.valueOf();
    }

    /**
     *
     * @param modelValue {*}
     * @returns {BeChatMessage}
     */
    static parseValue (modelValue) {

        if ( !modelValue ) {
            throw new TypeError(`${this.nrName}.parseValue(): modelValue was not defined`);
        }

        if ( modelValue instanceof BeChatMessage ) {
            return modelValue;
        }

        const {
            type
            , userId
            , content
            , payload
        } = modelValue;

        if ( type !== WaObjectType.CHAT_MESSAGE ) {
            throw new TypeError(`${this.nrName}.parseValue(): value's type is not correct: "${type}"`);
        }

        return new BeChatMessage({
            userId  : userId,
            content : content,
            payload : !_.isNil(payload) ? (NrModelUtils.isModel(payload) ? NrModelUtils.parseValue(payload) : _.cloneDeep(payload)) : undefined
        });

    }

}

// noinspection JSUnusedGlobalSymbols
export default BeChatMessage;
