/**
 *
 * @enum {string}
 * @readonly
 */
export const NrDirectiveName = {

    REGISTER_NG_MODEL_CONTROLLER : "nrRegisterNgModelController"

};

// noinspection JSUnusedGlobalSymbols
export default NrDirectiveName;
