import NrMessage from "@norjs/ui/models/views/NrMessage";
import LogUtils from "@norjs/utils/Log";
import WaStateName from "../../WaStateName";
import WaAttribute from "../../WaAttribute";

const nrLog = LogUtils.getLogger(`WaMessageViewController`);

export class WaMessageViewController {

	static getBindings () {
		return {
			model: `<?${WaAttribute.MODEL}`
		};
	}

	/**
	 *
	 * @param $state {angular.ui.IState}
	 * @ngInject
	 */
	constructor (
		$state
	) {
		'ngInject';

		/**
		 *
		 * @type {angular.ui.IState}
		 * @private
		 */
		this._$state = $state;

		/**
		 *
		 * @member {NrMessage}
		 * @private
		 */
		this._model = undefined;

	}

	// noinspection JSUnusedGlobalSymbols
	$onInit () {
	}

	$postLink () {
	}

	$onDestroy () {
	}

	/**
	 *
	 * @returns {NrMessage|undefined}
	 */
	get model () {

		return this._model;

	}

	/**
	 *
	 * @param value {NrMessage|undefined}
	 */
	set model (value) {

		if (value !== this._model) {
			this._setModel(value);
		}

	}

	/**
	 *
	 * @returns {boolean}
	 */
	hasModel () {
		return this._model ? this._model instanceof NrMessage : false;
	}

	/**
	 *
	 * @returns {boolean}
	 */
	hasIcon () {
		return !!(this._model && this._model.icon)
	}

	/**
	 *
	 * @returns {NrIconValue|string|undefined}
	 */
	getIconValue () {
		return this._model && this._model.icon ? this._model.icon.value : undefined;
	}

	/**
	 *
	 * @param payload {NrMessage}
	 * @private
	 */
	_setModel (payload) {

		this._model = payload;

		nrLog.debug(`Model set as: `, this._model);

	}

	// noinspection JSUnusedGlobalSymbols
	/**
	 *
	 */
	goMain () {

		nrLog.info(`Moving state to INDEX (${WaStateName.INDEX})`);
		this._$state.go(WaStateName.INDEX);

	}

}

// noinspection JSUnusedGlobalSymbols
export default WaMessageViewController;
