import angular from 'angular';
import waMainViewComponent from './waMainViewComponent.js';
import WaModuleName from "../../WaModuleName";
import WaTag from "../../WaTag";

export const waMainViewModule = angular.module(
	WaModuleName.MAIN_VIEW
	, [
	])
    .component(WaTag.MAIN_VIEW, waMainViewComponent)
	.name;

export default waMainViewModule;
