import angular from "angular";
import WaModuleName from "./WaModuleName";
import WaMain from './WaMain';

/**
 * @param $translateProvider
 * @ngInject
 */
function waConfig ($translateProvider) {
    "ngInject";
    WaMain.config($translateProvider);
}

/**
 * @param waEventService
 * @ngInject
 */
function waRun (waEventService) {
    "ngInject";
    WaMain.run(waEventService);
}

/**
 *
 * @type {string}
 */
export const waMainModule = angular.module(
    WaModuleName.APP,
    WaMain.getDependencies()
  ).config(waConfig)
  .run(waRun)
  .name;

// noinspection JSUnusedGlobalSymbols
export default waMainModule;
